import BaseEnum from "./BaseEnum";

const SuccessStoryUSPEnum = new BaseEnum(
  [
    "aaa-studios",
    "top-studios",
    "ctc-hike",
    "no-coding-experience",
    "first-job"
  ],
  [
    "Placed in AAA/International studio",
    "Transitioned from IT to Games",
    "% Salary Hike after Outscal",
    "No prior coding experience",
    "Started his career at a top studio"
  ]
);

export default SuccessStoryUSPEnum;