import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
import React from "react";
import styled from "styled-components";
import Image from "next/image";

const ImageWrap = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const ProfileImage = ({ profileImage, name }) => {
  return (
    <ImageWrap>
      <Image
        width={100}
        height={100}
        style={{
          objectFit: "cover",
          borderRadius: Theme.borderRadius.round,
        }}
        src={profileImage}
        alt={`${name}_linkedin_avatar`}
        quality={50}
      />
    </ImageWrap>
  );
};

export default ProfileImage;
