import { Div, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import React from "react";
import styled from "styled-components";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import Link from "next/link";
import { Button } from "@/outscal-commons-frontend/Styled/Buttons";
import { getSocialMediaButtonsData } from "./PersonaDetails.model";

const Root = styled(Div)`
  margin: 10px 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const PersonaDetailsView = ({ item }) => {
  const buttons = getSocialMediaButtonsData({ item });
  return (
    <Root>
      {Object.keys(buttons).map((key, index) => {
        const button = buttons[key];
        return button.link ? (
          <Link href={button.link} target="_blank" key={index} rel="nofollow">
            <Button
              shadow="none"
              color={button.color}
              hoverColor="white"
              hoverBackground={button.hoverBackground}
              border={button.border}
              background={button.bgLightColor}
              padding="8px 10px"
              borderRadius="8px"
              fontSize="14px"
              data-analytics={[
                UIElements.BUTTON,
                `${item.name}-key-placement-${key}`,
              ]}
            >
              <RowDiv alignItems="center" gap="6px">
                {button.label}
                {button.icon}
              </RowDiv>
            </Button>
          </Link>
        ) : (
          <Div></Div>
        );
      })}
    </Root>
  );
};

export default PersonaDetailsView;
