import React from "react";
import { ColDiv, Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import styled from "styled-components";
import Image from "next/image";
import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
import { FaArrowRightLong } from "react-icons/fa6";

const RowDiv = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
`;

const CompanyContainer = styled(ColDiv)`
  width: 50%;
`;

const CompanyDetails = styled(ColDiv)`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const CompanyName = styled.span`
  color: ${(p) => p.theme.colors.dimGray};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  font-size: ${(p) => p.theme.fontSize.md};
  padding: ${(p) => p.padding};
`;

const RightArrow = styled(FaArrowRightLong)`
  color: ${(p) => p.theme.colors.textDarkerGrey};
  font-size: 20px;
`;

const PlacementDetailsView = ({ item }) => {
  return (
    <RowDiv>
      <CompanyContainer>
        <CompanyName padding="0 24px">{item?.previousRole}</CompanyName>
      </CompanyContainer>
      <RightArrow />
      <CompanyContainer>
        <CompanyDetails>
          <CompanyName>{item?.company_name}</CompanyName>
          <Image
            width={40}
            height={40}
            style={{
              objectFit: "contain",
              borderRadius: Theme.borderRadius.xxxs,
              backgroundColor: Theme.colors.grey,
            }}
            src={item?.company_logo}
            alt={`${item?.company_name}`}
            quality={20}
          />
        </CompanyDetails>
      </CompanyContainer>
    </RowDiv>
  );
};

export default PlacementDetailsView;
