import React from "react";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import SuccessStoriesSectionController from "@/mvcComponents/PageComponents/CoursesPage/SuccessStoriesSection/SuccessStoriesSection.controller";

const PersonCCard_AutoScroll = ({
  heading = "",
  subHeading = "",
  listData = [],
  section_id = "",
  ctaText = "",
  ctaLink = "#",
  showOn,
}) => {
  return (
    <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section className="pc-auto-section">
        <SuccessStoriesSectionController
          id={section_id}
          heading={heading}
          subHeading={subHeading}
          listData={listData}
          ctaText={ctaText}
          ctaLink={ctaLink}
        />
      </section>
    </UseComponentVisibility>
  );
};

export default PersonCCard_AutoScroll;
