import React from "react";
import { FaBriefcase } from "react-icons/fa";
import ProfileImage from "./ProfileImage/ProfileImage.controller";
import IconWithText from "../../../../Components/IconWithText";
import { ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { Name, ProfileHeader, Root, UpperHalf } from "./PersonCCard.styles";
import PlacementDetails from "./PlacementDetails/PlacementDetails.controller";
import PlacementCardFooter from "./PlacementCardFooter/PlacementCardFooter.controller";

const PersonCCardView = ({ item, onCardClick, fixedWidth, href }) => {
  return (
    <Root onClick={onCardClick} fixedWidth={fixedWidth}>
      <UpperHalf>
        <ProfileHeader gap="12px">
          <ProfileImage profileImage={item?.image} name={item?.name} />
          <ColDiv>
            <Name
              rel="nofollow"
              href={href}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {item?.name}
            </Name>
            <IconWithText
              Icon={FaBriefcase}
              Text={item?.designation || "Game Developer"}
              IconColor={"#525769"}
              TextColor={"#525769"}
              TextSize={"16px"}
              IconWidth={"16px"}
              IconHeight={"16px"}
            />
          </ColDiv>
        </ProfileHeader>
        <PlacementDetails item={item} />
      </UpperHalf>
      <PlacementCardFooter item={item} />
    </Root>
  );
};

export default PersonCCardView;
