import React from "react";
import { sectionData } from "./SuccessStoriesSection.model";
import SuccessStoriesSectionView from "./SuccessStoriesSection.view";

const SuccessStoriesSectionController = ({
  id,
  heading = sectionData?.heading,
  listData,
  subHeading = sectionData?.subHeading,
  fixedWidth = sectionData?.fixedWidth,
  ctaText,
  ctaLink,
}) => {
  return (
    <SuccessStoriesSectionView
      ctcSortedSuccessStories={listData}
      id={id}
      heading={heading}
      subHeading={subHeading}
      fixedWidth={fixedWidth}
      ctaText={ctaText}
      ctaLink={ctaLink}
    />
  );
};

export default SuccessStoriesSectionController;
