import React from "react";
import PlacementCardFooterView from "./PlacementCardFooter.view";

const PlacementCardFooter = ({ item, navigateToExternalLinks = true }) => {
  return (
    <PlacementCardFooterView
      item={item}
      navigateToExternalLinks={navigateToExternalLinks}
    />
  );
};

export default PlacementCardFooter;
