import { Div, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import styled, { keyframes, css } from "styled-components";

export const Root = styled(Div)`
  padding-top: 20px;
`;

const slideAnimation = keyframes`
  0% {
    transform: translateZ(0)
  }
  100% {
    transform: translate3d(-1640px, 0, 0);
  }
`;

export const ListWrapper = styled(Div)`
  padding: 20px 0;
`;

export const InfiniteHorizontalSlider = styled(RowDiv)`
  animation: ${css`
      ${slideAnimation}`} 30s linear infinite;
  gap: 20px;
  animation-play-state: running;
  &:hover {
    animation-play-state: paused;
  }
`;