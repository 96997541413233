import React from "react";
import PersonCCardView from "./PersonCCard.view";

const PersonCCard = ({ item, customcardData }) => {
  const { utms, linkLogo, fixedWidth } = customcardData || {};
  const {
    image,
    name,
    previousRole = "",
    linkedinReview,
    company_name: currentCompany,
    company_logo,
    linkedIn = "",
    youTubeLink = "",
    designation,
    uspKey = [0],
    uspMetaData = "",
  } = item || {};

  const onCardClick = (e) => {
    const href = youTubeLink ? youTubeLink : linkedIn;
    window.open(href);
  };

  return (
    <PersonCCardView
      item={item}
      onCardClick={onCardClick}
      fixedWidth={fixedWidth}
      href={youTubeLink ? youTubeLink : linkedIn}
    />
  );
};

export default PersonCCard;
