import Link from "next/link";
import styled from "styled-components";
import { ColDiv, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";

export const Root = styled.div`
  border-radius: ${(p) => p.theme.borderRadius.xs};
  overflow: hidden;
  border: 1px solid ${(p) => p.theme.colors.borderGray};
  min-height: 300px;
  height: -webkit-fill-available;
  transition: all 0.2s ease-in-out;
  width: ${(p) => p.fixedWidth > 0 && `${p.fixedWidth}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    box-shadow: 0 2rem 2.4rem -0.4rem #10182814,
      0 0.8rem 0.8rem -0.4rem #10182808;
    -webkit-transform: scale(1.002);
    transform: scale(1.002);
  }
`;

export const ProfileHeader = styled(RowDiv)`
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const UpperHalf = styled(ColDiv)`
  padding: 12px 16px;
  height: 100%;
  gap: 12px;
`;

export const Name = styled(Link)`
  font-size: ${(p) => p.theme.fontSize.xl};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;
