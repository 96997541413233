import { SuccessStoryUSPEnum } from "@/outscal-commons-frontend/Enums";

export const uspBackgroundColors = {
  [SuccessStoryUSPEnum.getValue("top-studios")]: {
    bgColor: "bgLightPurple",
    textColor: "darkPurple",
  },
  [SuccessStoryUSPEnum.getValue("ctc-hike")]: {
    bgColor: "lightBgGreen",
    textColor: "darkGreen",
  },
  [SuccessStoryUSPEnum.getValue("aaa-studios")]: {
    bgColor: "bgLightPink",
    textColor: "deepMagenta",
  },
  [SuccessStoryUSPEnum.getValue("no-coding-experience")]: {
    bgColor: "seoLightBgYellow",
    textColor: "darkMud",
  },
  [SuccessStoryUSPEnum.getValue("first-job")]: {
    bgColor: "bgAqua",
    textColor: "darkCyanBlue",
  },
};
