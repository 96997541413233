import React from "react";
import { Line, USPTag } from "./PlacementCardFooter.styles";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { SuccessStoryUSPEnum } from "@/outscal-commons-frontend/Enums";
import { uspBackgroundColors } from "@/staticData/successStoriesUSPData";
import PersonaDetailsController from "../PersonaDetails/PersonaDetails.controller";

const PlacementCardFooterView = ({ item, navigateToExternalLinks }) => {
  return (
    <Div>
      {SuccessStoryUSPEnum.getDisplayValueFromIndex(item?.uspKey) && (
        <USPTag colors={uspBackgroundColors[item?.uspKey]}>
          {item?.uspMetaData ||
            "" + SuccessStoryUSPEnum.getDisplayValueFromIndex(item?.uspKey)}
        </USPTag>
      )}
      {navigateToExternalLinks && (item?.linkedIn || item?.youTubeLink) && (
        <>
          <Line />
          <PersonaDetailsController item={item} />
        </>
      )}
    </Div>
  );
};

export default PlacementCardFooterView;
