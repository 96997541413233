import React from "react";
import { RowDiv } from "@/outscal-commons-frontend/Styled";
import {
  Root,
  ListWrapper,
  InfiniteHorizontalSlider,
} from "./Placements.styles";
import PersonCCard from "@/mvcComponents/CommonUI/Cards/PersonCCard/PersonCCard.controller";

const PlacementsView = ({ successStories, fixedWidth }) => {
  return (
    <Root>
      <ListWrapper>
        <InfiniteHorizontalSlider>
          <RowDiv gap="20px">
            {successStories?.map((successStory) => (
              <PersonCCard
                key={successStory?.id}
                item={successStory}
                customcardData={{
                  fixedWidth,
                }}
              />
            ))}
          </RowDiv>
        </InfiniteHorizontalSlider>
      </ListWrapper>
    </Root>
  );
};

export default PlacementsView;
