import styled from "styled-components";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";

export const USPTag = styled(Div)`
  margin: 12px;
  padding: 8px 12px;
  text-align: center;
  background-color: ${(p) =>
    p.theme.colors[p?.colors?.bgColor] || p.theme.colors.backgroundGrey};
  color: ${(p) =>
    p.theme.colors[p?.colors?.textColor] || p.theme.colors.textDarkGrey};
  height: fit-content;
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  font-size: ${(p) => p.theme.fontSize.sm};
`;

export const Line = styled.div`
  border-top: 0.5px solid ${(p) => p.theme.colors.borderGray};
`;
