import { IoLogoYoutube } from "react-icons/io5";
import { ImLinkedin } from "react-icons/im";

export const getSocialMediaButtonsData = ({ item }) => {
  return {
    youtube: {
      label: "YouTube",
      icon: <IoLogoYoutube fontSize="18px" />,
      color: "darkRed",
      bgHoverColor: "white",
      hoverBackground: "red",
      border: "1px solid",
      bgLightColor: "bgLightRed",
      link: item?.youTubeLink,
    },
    linkedin: {
      label: "Connect",
      icon: <ImLinkedin fontSize="18px" />,
      color: "darkCyanBlue",
      border: "1px solid",
      bgHoverColor: "white",
      hoverBackground: "darkCyanBlue",
      bgLightColor: "bgLightBlue",
      link: item?.linkedIn,
    },
  };
};
