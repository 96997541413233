import React from "react";
import PlacementsController from "./Placements/Placements.controller";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import {
  Root,
  Heading,
  SubHeading,
  AllStoriesLink,
} from "./SuccessStoriesSection.styles";

const SuccessStoriesSectionView = ({
  heading,
  subHeading,
  ctcSortedSuccessStories,
  id,
  fixedWidth,
  ctaText,
  ctaLink,
}) => {
  return (
    <Root id={id}>
      <Heading>{heading}</Heading>
      <SubHeading>{subHeading}</SubHeading>
      <PlacementsController
        successStories={ctcSortedSuccessStories}
        fixedWidth={fixedWidth}
      />
      {ctaLink && (
        <AllStoriesLink
          href={ctaLink}
          data-analytics={[UIElements.LINK, `${id}-cta-from-courses`]}
        >
          {ctaText}
        </AllStoriesLink>
      )}
    </Root>
  );
};

export default SuccessStoriesSectionView;
