import React from "react";
import PlacementsView from "./Placements.view";

const PlacementsController = ({
  successStories = [],
  fixedWidth
}) => {
  return (
    <PlacementsView
      successStories={successStories}
      fixedWidth={fixedWidth}
    />
  );
};

export default PlacementsController;
