import Link from "next/link";
import React from "react";
import { styled } from "styled-components";

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: max-content;
  cursor: pointer;
`;

const Label = styled.p`
  line-height: 25px;
  color: ${(p) => (p.color ? p.color : p.theme.colors.dimGray)};
  ${(p) => p.size && `font-size: ${p.size}`};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(p) =>
    p.variant === "chip" &&
    `
      padding: 0px 7px;
      background-color: ${p.background};
      border-radius: ${p.theme.borderRadius.sm};
  `}
`;

const IconWithText = ({
  Icon,
  Text,
  IconHeight,
  IconWidth,
  IconBottom,
  IconRight,
  TextColor,
  TextSize,
  IconColor,
  link,
  variant,
  chipColor
}) => {
  const IconStyle = {
    width: IconWidth ? IconWidth : "17px",
    height: IconHeight ? IconHeight : "17px",
    fill: IconColor || "gray",
    "margin-bottom": IconBottom ? IconBottom : "-2px",
    "margin-right": Text ? (IconRight ? IconRight : "5px") : "0px",
  };

  return (
    <Root>
      {link ? (
        <Link href={link}>
          <Label
            size={TextSize}
            color={TextColor}
            variant={variant}
            background={chipColor}
            data-tooltip-id="data-tip"
            data-tooltip-content={Text}
          >
            <Icon style={IconStyle} />
            {Text}
          </Label>
        </Link>
      ) : (
        <Label
          size={TextSize}
          color={TextColor}
          variant={variant}
          background={chipColor}
          data-tooltip-id="data-tip"
          data-tooltip-content={Text}
        >
          <Icon style={IconStyle} />
          {Text}
        </Label>
      )}
    </Root>
  );
};

export default IconWithText;
