import Link from "next/link";
import styled from "styled-components";
import { ColDiv, Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";

export const Root = styled(ColDiv)`
  align-items: center;
  width: 100%;
  overflow-x: hidden;
`;

export const Heading = styled.h2`
  font-size: ${(p) => p.theme.fontSize["5xl"]};
  text-align: center;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    font-size: ${(p) => p.theme.fontSize.xxxxl};
  }
`;

export const SubHeading = styled.h3`
  font-size: ${(p) => p.theme.fontSize.lg};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  color: ${(p) => p.theme.colors.textDarkerGrey};
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: ${(p) => p.theme.fontSize.md};
  }
`;

export const AllStoriesLink = styled(Link)`
  padding: 8px 16px;
  font-size: ${(p) => p.theme.fontSize.md};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  border: 1px solid ${(p) => p.theme.colors.themeBlue};
  background-color: ${(p) => p.theme.colors.themeBlue};
  color: ${(p) => p.theme.colors.white};
  transition: all 0.3s;
  &:hover {
    background-color: ${(p) => p.theme.colors.bgLightBlue};
    color: ${(p) => p.theme.colors.themeBlue};
  }
`;
